<template>
  <div>
    <div class="venueCard">
      <a-collapse v-model="activeKey" expandIconPosition="right" :bordered="false">
        <a-collapse-panel key="1" header="需求信息">
          <itemInfo :venueItem='venueItem'></itemInfo>
        </a-collapse-panel>
        <a-collapse-panel key="2" :header="'费用类型:' + venueItem.itemTypeTxt">
          <p class="quoteTips">如10间房以上或因其他原因而需要设定担保数量的情况，请在备注中说明</p>
          <a-form :form="form">
            <a-row :gutter='16'>
              <template v-for="(item, index) in formList">
                <a-Col :span="['AutoComplete', 'timePicker'].includes(item.type) ? 24 : item.span || 12" :key='index'>
                  <a-form-item v-if="!['timePicker'].includes(item.type)" :label="item.txt" :key='"form-"+index'>
                    <a-input-number v-if="['Number'].includes(item.type)" :disabled='item.disabled || !isEdit'
                    @change="(val) => (handleChange(item.operate, val))" style="width: 100%;" :min='0' :placeholder='item.placeholder' 
                    v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>

                    <a-radio-group v-else-if="['radio'].includes(item.type)" :disabled='item.disabled || !isEdit' :placeholder='item.placeholder' 
                    v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]">
                      <a-radio v-for="(ele, i) in item.options" :key="i" :value="ele.val"> {{ele.txt}}</a-radio>
                    </a-radio-group>

                    <a-select v-else-if="['select'].includes(item.type)" style="width: 100%" :disabled='item.disabled || !isEdit' :options='item.options'
                    :placeholder='item.placeholder' @change="(val) => (handleSelectChange(item.operate, item, val))"
                    v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"></a-select>

                    <FinderFrame v-else-if="['file'].includes(item.type)" :gateway="gateway" ref="FinderFrame" :isPreview="true" :h5FilePreview="false" :actions="actions"
                    :onlyPreview="!isEdit" :filterData="mappingData" :isMapping="true" :uploadTimeout="120000" @uploadCallback="() => {}" />

                    <a-textarea v-else-if="['TextArea'].includes(item.type)" :autoSize="{ minRows: 2, maxRows: 6 }" :disabled='item.disabled || !isEdit' 
                    :placeholder='item.placeholder' v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>

                    <a-auto-complete v-else-if="['AutoComplete'].includes(item.type)" :disabled='item.disabled || !isEdit' :placeholder='item.placeholder'
                    @change='onChange' option-label-prop="value" :dataSource="options" v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>

                    <div v-else-if="['price'].includes(item.type)">
                      <a-input-number :disabled='item.disabled || !isEdit' style="width: 100%;" :placeholder='item.placeholder' @change='handleChangePrice' :min="0" 
                      v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>
                    </div>

                    <div v-else-if="['DatePicker'].includes(item.type)">
                      <a-date-picker :disabled='item.disabled || !isEdit' style="width: 100%;" :placeholder='item.placeholder'
                      v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>
                    </div>
                    <a-input v-else style="width: 100%" :disabled='item.disabled || !isEdit' :placeholder='item.placeholder'
                    v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>
                  </a-form-item>
                  <a-form-item v-else-if="['timePicker'].includes(item.type)" :required="true" :label="item.txt" :key='"time-"+index' style="margin-bottom: 0;" >
                    <a-form-item style="width: 45%;display: inline-block;" :key='"timeItem1-"+index'>
                      <a-time-picker v-decorator="['venueTimeStart', { rules: [{ required: true, message: '请选择开始时间' }] }]" 
                        placeholder="开始时间" format="HH:mm" style="width: 100%;" :disabled='item.disabled || !isEdit'/>
                    </a-form-item>
                    <span style="display: inline-block; width: 10%; text-align: center;">至</span>
                    <a-form-item style="width: 45%;display: inline-block;" :key='"timeItem2-"+index'>
                      <a-time-picker v-decorator="['venueTimeEnd', { rules: [{ required: true, message: '请选择结束时间' }] }]" 
                      placeholder="结束时间" format="HH:mm" style="width: 100%;" :disabled='item.disabled || !isEdit'/>
                    </a-form-item>
                  </a-form-item>
                </a-Col>
              </template>
            </a-row>
          </a-form>
        </a-collapse-panel>
        <a-collapse-panel v-for="(item, index) in teaList" header="费用类型:茶歇" :key="'tea-' + index">
          <teaQuote ref="teaQuote" :venueItem="item" :isGsk="isGsk" :formList="teaFormList"></teaQuote>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>
<script>
import {FinderFrame} from "smart-filer-vue";
import "smart-filer-vue/lib/index.css";
import itemInfo from "../itemInfo.vue";
import teaQuote from "./teaQuote.vue";
import moment from 'moment'
export default {
  name: 'venueCard',
  components: {
    FinderFrame,
    itemInfo,
    teaQuote
  },
  props: {
    venueItem: {
      type: Object,
      default(){
        return {
          teaList: []
        }
      }
    },
    isGsk: {
      type: Boolean,
      default: false
    },
    formList: {
      type: Array,
      default() {
        return []
      }
    },
    teaFormList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data () {
    return {
      quotation: {},
      formData: [],
      fileKeys: [],
      options: [],
      teaList: this.venueItem.teaList,
      activeKey: ['1','2'],
      lowPrice: undefined,
      showBeyondCause: false,
      form: this.$form.createForm(this, {
        name: 'venueQuote', // 表单域内字段 id 的前缀
      }),
      supplierInfo: this.$SmartStorage.get('supplierInfo'),
      isShow: true,
      isEdit:true,
      gateway: process.env.VUE_APP_GATEWAY,
      actions: [
        {actionType: "carame", actionLabel: "拍照"},
        { actionType: "album", actionLabel: "文件", accepts: "image/*,.doc,.docx,.pdf,.xls,.xlsx" }
      ],
      mappingData: {
        processId: this.$SmartStorage.get('proposalId'),
        itemId: this.venueItem.itemId,
        supplierId: this.$SmartStorage.get('supplierId'),
        catalog: "报价文件"
      }
    }
  },
  mounted() {
    this.int()
  },
  created() {
    this.isEdit = this.$SmartStorage.get('isEdit')
    this.GetItemVenueAndRooms()
  },
  methods: {
    async GetItemVenueAndRooms () {
      let params = {
        proposalId: this.$SmartStorage.get('proposalId'),
        itemId: this.venueItem.itemId,
        supplierId: this.$SmartStorage.get('supplierId'),
      }
      let res = await this.$service.GetItemVenueAndRooms(params)
      if (res && res.success) {
        const content = res.content.map(ele => ({
          ...ele,
          value: ele.name || '',
          text: `${ele.name || ''}参考价：${ele.price ? ele.price : '无'}`,
        }));
        this.options = content
      }
    },
    onChange (searchText) {
      this.lowPrice = undefined;
      this.options.forEach(ele => {
        if ([searchText].includes(ele.name)) {
          this.lowPrice = Number(ele.lowPrice);
        }
      });
    },
    handleChangePrice (data) {
        this.showBeyondCause = data < this.lowPrice;
        const qty = this.form.getFieldValue("qty") || 1;
        const total = Number(data) * Number(qty);
        this.form.setFieldsValue({'amount': total});
    },
    removeFirstLevelNulls  (obj) {
        const newObj = {};
        for (const key in obj) {
          if (obj[key] !== null && obj[key] !== "") {
            newObj[key] = obj[key];
          }
        }
        return newObj;
    },
    int () {
      setTimeout(()=> {
        let data = this.venueItem
        let formItems = this.formList
        const teaKeys = this.teaList.map((_, index) => `tea-${index}`);
        this.activeKey = ['1', '2', ...teaKeys];
        if (data.expenses.length && formItems) {
          let expenses = data.expenses[0]
          if ([null, undefined].includes(expenses.agreementPrice)) {
            expenses.agreementPrice = Boolean(this.isGsk && !this.supplierInfo.inWhiteList);
          }
          if (expenses.venueDate) {
            expenses.venueDtstart = moment(expenses.venueDate, 'YYYY/MM/DD')
          }
          if (expenses.venueTimeStart && expenses.venueTimeEnd) {
            expenses.venueTimeStart = moment(expenses.venueTimeStart, 'HH:mm')
            expenses.venueTimeEnd = moment(expenses.venueTimeEnd, 'HH:mm')
          }
          const fieldsValue = {};
          formItems.forEach(ele => {
            const data = ![null, undefined].includes(expenses[ele.val]) ? expenses[ele.val] : ele.details
            if (['venueTime'].includes(ele.val)) {
              fieldsValue.venueTimeStart = expenses.venueTimeStart || ele.details;
              fieldsValue.venueTimeEnd = expenses.venueTimeEnd || ele.details;
              ele.disabled = !['其他'].includes(expenses.venueTimePeriod)
            } else {
              fieldsValue[ele.val] = data;
            }
          });
          this.$nextTick(() => {
            this.form.setFieldsValue(fieldsValue);
          })
        }
      }, 20)
    },
    handleSelectChange (operate,item ,val) {
      if (['venueTimePeriod'].includes(item.val)) {
        let formItems = [...this.formList]
        let itemDisabled = false
        if (!['其他'].includes(val)) {
          let times = val.match(/（(.*?)）/)[1].split('~').map(t => moment(t.trim(), 'HH:mm'));
          itemDisabled = true;
          this.$nextTick(() => {
            this.form.setFieldsValue({ venueTimeStart: times[0], venueTimeEnd: times[1] });
          })
        }
        formItems.forEach(ele => {
        if (ele.val === 'venueTime') {
          ele.disabled = itemDisabled;
        }
      });
      } else if (operate && operate.length) {
        operate.forEach((ele) => {
          const operateTxt = ele.operate ? ele.operate.replace(/"/g, "") : null;
          const result = ele.operate ? eval(operateTxt) : 0;
          this.form.setFieldsValue({ [ele.result]: result });
        });
      }
    },
    handleChange(operate, val) {
      console.log(val)
      let form = this.form
      console.log(form)
      if (operate && operate.length) {
        operate.forEach(ele => {
          const operateTxt = ele.operate ? ele.operate.replace(/"/g, "") : null;
          const result = ele.operate ? eval(operateTxt) : 0;
          this.form.setFieldsValue({ [ele.result]: result });  // 使用 this 访问 form 对象
        });
      }
    },
    async editInfo () {
        const teaData = await Promise.all((this.$refs.teaQuote || []).map((ref) => ref.editInfo()));
        let teaListData = []
        let result = true
        await teaData.map(item => {
          if (item.result) {
            teaListData.push(item.params)
          } else {
            result = false
          }
        })
        if (!result) {
          return { result: false };
        }
        return this.form.validateFields().then(values => {
        if (values.minQty > values.qty) {
            this.$message.error('保底数量需在预计人数范围内');
            return {result: false};  // 或者抛出一个错误
        }
        const params = this.createSaveParams(values, teaListData);
        return {result: true, params:params};  // 返回 params 给父组件
      }).catch(error => {
        console.error('Validation failed:', error);
        return { result: false };
      });
    },
    createSaveParams (values, teaData) {
      let selectData = this.venueItem
      let res = this.$refs.FinderFrame.fileList || []
      let fileKeys = res.map(ele => ele.fileKey)
      const taxRate = Number(values.taxRate) / 100;
        const expenseData = {
            venueDate: values.venueDtstart ? moment(values.venueDtstart).format('YYYY/MM/DD') : '',
            venueTimeStart: moment(values.venueTimeStart).format('HH:mm'),
            venueTimeEnd: moment(values.venueTimeEnd).format('HH:mm'),
            venueTimePeriod: values.venueTimePeriod
        }
        delete values.venueDtstart
        const quote = Object.assign(values, {
            costType: selectData.costType,
            tax: Number((values.amount / (1 + taxRate) * taxRate).toFixed(2)),
            files: fileKeys,
            expenseData: JSON.stringify(expenseData)
        }, expenseData);
        if (selectData.expenses?.[0].expenseId) {
            quote.expenseId = selectData.expenses[0].expenseId;
        }
        if (this.lowPrice !== undefined) {
            quote.lowPrice = this.lowPrice;
        }
        return {
          tenantId: this.$SmartStorage.get('tenantId'),
          miceId: this.$SmartStorage.get('proposalId'),
          orderId: this.$SmartStorage.get('orderId') || null,
          itemId: this.venueItem.itemId,
          supplierId: this.$SmartStorage.get('supplierId'),
          quotes: [quote, ...teaData],
        };
    },
  }
}
</script>
<style scoped lang="scss">
.venueCard {
  box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.15);
  border-radius: .04rem;
  margin: .1rem;
  background: #ffffff;
  position: relative;
  text-align: left;
  .errorTips {
    position:absolute;
    bottom: 0;
  }
}
</style>
<style lang="scss">
.venueCard {
  .ant-collapse-borderless{
    background: #fff;
  }
  .ant-collapse-header {
    font-weight: bold;
    font-size: 17px;
    color: #13161A;
  }
}
</style>