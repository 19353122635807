<template>
  <div>
    <div class="venueCard">
      <a-form :form="form">
            <a-row :gutter='16'>
              <template v-for="(item, index) in formList">
                <a-Col :span="['AutoComplete'].includes(item.type) ? 24 : item.span || 12" :key='index'>
                  <a-form-item :label="item.txt" v-if="['Number'].includes(item.type)">
                    <a-input-number  :disabled='item.disabled || !isEdit'
                    @change="(val) => (handleChange(item.operate, val))" style="width: 100%;" :min='0' :placeholder='item.placeholder' 
                    v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>
                  </a-form-item>
                  <a-form-item :label="item.txt" v-else-if="['radio'].includes(item.type)">
                    <a-radio-group :disabled='item.disabled || !isEdit' :placeholder='item.placeholder' 
                    v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"
                     @change="(val) => (handleRadioChange(item, val))">
                      <a-radio v-for="(ele, i) in item.options" :key="i" :value="ele.val"> {{ele.txt}}</a-radio>
                    </a-radio-group>
                  </a-form-item>
                  <a-form-item :label="item.txt" v-else-if="['select'].includes(item.type)">
                    <a-select style="width: 100%" :disabled='item.disabled || !isEdit' :options='item.options'
                    :placeholder='item.placeholder' v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"></a-select>
                  </a-form-item>
                  <a-form-item :label="item.txt" v-else-if="['TextArea'].includes(item.type)">
                    <a-textarea :autoSize="{ minRows: 2, maxRows: 6 }" :disabled='item.disabled || !isEdit' 
                    :placeholder='item.placeholder' v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>
                  </a-form-item>
                  <a-form-item :label="item.txt" v-else>  
                    <a-input style="width: 100%" :disabled='item.disabled || !isEdit' :placeholder='item.placeholder'
                    v-decorator="[item.val, {rules: [{ required: item.required, message: item.message }]}]"/>
                  </a-form-item>
                </a-Col>
              </template>
            </a-row>
          </a-form>
    </div>
  </div>
</template>
<script>
export default {
  name: 'demandCard',
  props: {
    venueItem: {
      type: Object,
      default(){
        return {}
      }
    },
    isGsk: {
      type: Boolean,
      default: false
    },
    formList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data () {
    return {
      formData: [],
      form: this.$form.createForm(this, {
        name: 'teaForm', // 表单域内字段 id 的前缀
      }),
      supplierInfo: this.$SmartStorage.get('supplierInfo'),
      isShow: true,
      isEdit:true,
    }
  },
  created() {
    this.isEdit = this.$SmartStorage.get('isEdit')
    this.int()
  },
  methods: {
    int () {
      setTimeout(()=> {
        let data = this.venueItem
        let formItems = this.formList
        if (data.expenses.length && formItems) {
          const expenses = { ...data, ...data.expenses[0] };
          let quoteModeData = 1
          if ([null, undefined].includes(expenses.agreementPrice)) {
              expenses.agreementPrice = this.isGsk && !this.supplierInfo.inWhiteList;
          }
          const fieldsValue = {};
          formItems.forEach(ele => {
            const values = ![null, undefined].includes(expenses[ele.val]) ? expenses[ele.val] : ele.details
            if (['quoteMode'].includes(ele.val)) {
                quoteModeData =  values
            }
            if (['price'].includes(ele.val)) {
                ele.disabled = [1].includes(quoteModeData)
            }
            if (['amount'].includes(ele.val)) {
                ele.disabled = [2].includes(quoteModeData)
            }
            fieldsValue[ele.val] = values;
          });
          this.$nextTick(() => {
            this.form.setFieldsValue(fieldsValue);
          })
        }
      }, 2000)
    },
    handleRadioChange (item, val) {
        let formItems = [...this.formList]
        if (['quoteMode'].includes(item.val)) {
            formItems.forEach(item => {
                if (['price'].includes(item.val)) {
                    item.disabled =  [1].includes(val.target.value)
                }
                if (['amount'].includes(item.val)) {
                    item.disabled =  [2].includes(val.target.value)
                }
            })
        }
    },
    handleChange(operate, val) {
      console.log(val)
      let form = this.form
      console.log(form)
      if (operate && operate.length) {
        operate.forEach(ele => {
          const operateTxt = ele.operate ? ele.operate.replace(/"/g, "") : null;
          const result = ele.operate ? eval(operateTxt) : 0;
          this.form.setFieldsValue({ [ele.result]: result });  // 使用 this 访问 form 对象
        });
      }
    },
    editInfo () {
        return this.form.validateFields().then(values => {
        const params = this.createSaveParams(values);
        return {result: true, params:params};  // 返回 params 给父组件
      }).catch(error => {
        console.error('Validation failed:', error);
        return { result: false };
      });
    },
    createSaveParams (values) {
      let selectData = this.venueItem
      const taxRate = Number(values.taxRate) / 100;
        const expenseData = {
            quoteMode: values.quoteMode
        }
        const quote = {
            ...values,
            costType: selectData.costType,
            tax: Number((values.amount / (1 + taxRate) * taxRate).toFixed(2)),
            expenseData: JSON.stringify(expenseData)
        };
        if (selectData.expenses?.[0]?.expenseId) {
            quote.expenseId = selectData.expenses[0].expenseId;
        }
        return quote;
    },
  }
}
</script>