<template>
  <div class="venueInfo">
    <div class="venueItem">
      <div class="venueLabel">项目名称:</div>
      <div class="venueTxt">{{ venueItem.itemTypeTxt }}</div>
    </div>
    <div class="venueItem">
      <div class="venueLabel">日期+时间段:</div>
      <div class="venueTxt">{{ venueItem.dtStart ? venueItem.dtStart.split('T')[0] : '' }} {{ venueItem.venueTimeStart }} {{ venueItem.venueTimeEnd }}</div>
    </div>
    <div class="venueItem">
      <div class="venueLabel">类型:</div>
      <div class="venueTxt">{{[2].includes(venueItem.itemType) ? venueItem.mealType : venueItem.itemName}}</div>
    </div>
    <div class="venueItem">
      <div class="venueLabel">数量:</div>
      <div class="venueTxt">{{ venueItem.qty1 }}</div>
    </div>
    <div class="venueItem">
      <div class="venueLabel">小计:</div>
      <div class="venueTxt">{{ venueItem.amount }}</div>
    </div>
    <template v-if="venueItem.teaList">
      <div class="venueItem" v-for="(item, index) in venueItem.teaList" :key="index">
        <div class="venueLabel">茶歇{{ index + 1 }}:</div>
        <div class="venueTxt"><span>{{ item.dtStart ? item.dtStart.split('T')[0] : '' }}</span><span v-if="item.qty1">{{ item.qty1 }} 人</span></div>
      </div>
    </template>
    <div class="venueItem">
      <div class="venueLabel">备注:</div>
      <div class="venueTxt">{{ venueItem.descr }}</div>
    </div>
  </div>
</template>
<script>
export default({
  props: {
    venueItem: {
      type: Object,
      default(){
        return {}
      }
    },
  }
})
</script>
<style lang="scss" scoped>
.venueInfo {
  .venueItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: #13161B;
    margin-bottom: .1rem;
    .venueLabel {
      min-width: 50px;
    }
    .venueTxt {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
</style>
