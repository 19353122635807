<template>
  <div>
    <div v-for="(item, index) in venuesList" :key="index">
      <venue-card :venueItem="item" ref="venueCard" :formList='formList' :teaFormList='teaFormList' :isGsk="isGsk"></venue-card>
    </div>
    <div class="saveQuote" @click="SaveQuotes">
      <a-spin v-show="loading"/>
      保存此品类报价
    </div>
  </div>
</template>
<script>
import venueCard from "@/view/supplierQuote/components/venueQuote/venueCard";
export default {
  components: {
    venueCard
  },
  props: {
    supplierInfo: {
      type: Object,
      default(){
        return {
          items: []
        }
      }
    },
    isGsk: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      venuesList: [],
      formList: [],
      teaFormList: [],
      isGSK: false,
      loading: false,
    }
  },
  watch: {
    supplierInfo () {
      console.log(this.supplierInfo)
      this.venuesList = []
      this.venuesList = this.transformData(this.supplierInfo.items)
    }
  },
  created() {
    this.venuesList = []
    this.venuesList = this.transformData(this.supplierInfo.items)
    this.getFormList(1)
    this.getFormList(5)
  },
  methods: {
    async getFormList (type) {
      let params ={
        collection: "cfg-supplier-HotelQuotationFormItems",
        filter: {
            tenantId: { "$in": ["*", this.$SmartStorage.get('tenantId')] },
            itemType: type,
        },
      }
      let res = await this.$service.getsettings(params)
      if (res && res.content) {
        if ([1].includes(type)) {
          this.formList =  res.content.child || []
        } else {
          this.teaFormList = res.content.child || []
        }
        this.isGSK = res.content.isGSK
      }
    },
    transformData(data) {
      // 创建一个 Map 来按 itemId 进行分组
      const groupedData = new Map();
      if (!data) {
        return
      }
      data.forEach(item => {
          const { itemId, costType } = item;
          // 如果 Map 中没有这个 itemId 的 key，就初始化一个对象
          if (!groupedData.has(itemId)) {
              groupedData.set(itemId, {
                  ...item,
                  teaList: []
              });
          }
          // 将茶歇项添加到 teaList 中，场租项保持不变
          if (costType === '茶歇') {
              groupedData.get(itemId).teaList.push(item);
          } else {
              groupedData.set(itemId, { ...item, teaList: groupedData.get(itemId).teaList });
          }
      });
      // 将 Map 转换为数组
      return Array.from(groupedData.values());
    },
    async SaveQuotes () {
      if (this.loading) {
        return
      }
      let paramsList = []
      const data = await Promise.all(this.$refs.venueCard.map((ref) => ref.editInfo()));
      let result = true
      await data.map(item => {
        if (item.result) {
          paramsList.push(item.params)
        } else {
          result = false
        }
      })
      if (!result) {
        return 
      }
      this.loading = true
      let res = await this.$service.BatchSaveQuotes(paramsList)
      this.loading = false
      if (res && res.success) {
        this.$toast("酒店报价保存成功！");
        this.$emit('updateAmount')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.saveQuote {
  background: #E1EAFF;
  border-radius: 4px;
  border: 1px solid #3875FE;
  font-size: .17rem;
  color: #3875FE;
  margin: 0 0.1rem;
  padding: 0.1rem;
  position: fixed;
  width: calc(100% - 0.2rem);
  bottom: 0.9rem;
}
</style>
