<template>
  <section>
    <div class="smallVenueTitle">
      <div class="venueTitle">酒店报价</div>
      <venue-info :supplierInfo="supplierInfo"></venue-info>
    </div>
    <venue-type :supplierInfo="supplierItemExpenses" @updateItem="updateItem"></venue-type>
    <div class="venueBox">
      <venue-quote :supplierInfo="selectData" v-show="['酒店会场'].includes(isSelected)"
       @updateAmount="updateAmount"></venue-quote>
      <demand-quote :key="isSelected" :supplierInfo="selectData" @updateAmount="updateAmount"
      v-if="['酒店其他','酒店用餐', '酒店住宿'].includes(isSelected)" />
      <clause-page v-if="['报价有效期及取消条款'].includes(isSelected)"></clause-page>
    </div>
    <a-drawer placement="bottom" :closable="false" :visible="visible" @close="visible = false">
      <div class="amountDetailsBox" v-for="(item, index) in supplierItemExpenses" :key='index'>
        <p>{{ item.itemTypeTxt }}总金额</p>
        <p>{{ item.amount }}</p>
      </div>
    </a-drawer>
    <footer class="footer_module" v-if="isEdit">
      <div class="btn_container">
        <div class="infoBox">
          <div class="amountBox">
            <p><span class="amount">{{ supplierInfo.quote }}</span> <span>元</span></p>
            <p>总报价</p>
          </div>
          <div class="detailsBtn" @click="visible = !visible">
            查看明细
            <a-icon type="down" v-show="visible"/>
            <a-icon type="up" v-show="!visible"/>
          </div>
        </div>
        <p class="btn" :class="isSubmit ? '' : 'noSubmit'" @click="saveDetail">
          <span>提交报价</span>
        </p>
      </div>
    </footer>
  </section>
</template>
<script>
import VenueInfo from "@/view/supplierQuote/components/venueInfo";
import venueType from "@/view/supplierQuote/components/venueType";
import venueQuote from './components/./venueQuote/venueQuote'
import demandQuote from "./components/demandQuote/demandQuote.vue";
import clausePage from "@/view/supplierQuote/components/clausePage";
export default {
  components: {
    VenueInfo,
    venueType,
    venueQuote,
    demandQuote,
    clausePage
  },
  data () {
    return {
      supplierInfo: {},
      supplierItemExpenses: [],
      isSelected: '',
      selectData: {},
      rightTxt: '',
      btnInfo: {},
      isEdit: false,
      isGsk: false,
      visible: false,
      isSubmit: true,
    }
  },
  created() {
    this.GetF1QuotedPriceTaskList()
    this.queryVenueOrderSuppliers()
    this.queryVenueSuppliers()
  },
  methods:{
    updateAmount () {
      this.queryVenueOrderSuppliers()
      this.queryVenueSuppliers()
    },
    async GetF1QuotedPriceTaskList () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId'),
      }
      let res = await this.$service.GetF1QuotedPriceTaskList(params);
      if (res && res.success) {
        let result = res.content.filter((li) => {
          return li.supplierId == this.$SmartStorage.get('supplierId')
        })[0]
        let result2 =[0, '0'].includes(result.taskStatus)
        this.isEdit = result2
        this.$SmartStorage.set('isEdit', result2)
      }
    },
    async queryVenueOrderSuppliers () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId'),
      }
      let res = await this.$service.QueryVenueOrderSuppliers(params);
      if (res && res.success) {
        this.supplierInfo = res.content.filter((li) => {
          return li.supplierId == this.$SmartStorage.get('supplierId')
        })[0]
        this.$SmartStorage.set('supplierInfo', this.supplierInfo)
      }
    },
    async queryVenueSuppliers () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId'),
        supplierId: this.$SmartStorage.get('supplierId')
      }
      let res = await this.$service.QueryVenueOrderSupplierItemExpenses(params);
      if (res && res.success) {
        this.isSubmit = true
        res.content.forEach(ele => {
          ele.items.forEach(li => {
            if (!li.fillExpenseStatus) {
              this.isSubmit = false
            }
          })
        });
        this.supplierItemExpenses = res.content
        if (this.isSelected) {
          this.selectData = res.content.filter(ele => {return [ele.itemTypeTxt].includes(this.isSelected)} )[0]
        } else if (res.content.length) {
          this.isSelected = res.content[0].itemTypeTxt
          this.selectData = res.content[0]
        }
      }
    },
    updateItem (val) {
      this.isSelected = val.itemTypeTxt
      this.selectData = val
    },
    saveDetail () {
      if (!this.isSubmit) {
        return
      }
      this.$dialog.confirm({
        title: '提示',
        messageAlign: 'left',
        message: '是否确定提交报价',
      }).then(async() => {
        let params = {
          actionId: this.$SmartStorage.get('actionId'),
          formData: JSON.stringify({
            supplierId: this.$SmartStorage.get('supplierId'),
            orderId: this.$SmartStorage.get('orderId')
          }),
          nodeId: this.$SmartStorage.get('nodeId'),
          processId: this.$SmartStorage.get('proposalId'),
          taskData:  JSON.stringify({
            supplierId: this.$SmartStorage.get('supplierId'),
            orderId: this.$SmartStorage.get('orderId')
          }),
          taskId: this.$SmartStorage.get('taskId'),
          orderId: this.$SmartStorage.get('orderId'),
        }

        let headers = {}
        if (this.$SmartStorage.get('authMobile')) {
          headers = {
            headers: {"authmobile": this.$SmartStorage.get('authMobile')}
          }
        }
        let res = await this.$service.ProcessTask(params, headers)
        if (res.success) {
          this.SaveMiceAssistantInfo()
        }
      }).catch(() => {});
    },
    async SaveMiceAssistantInfo () {
      let params = {
        miceId: this.$SmartStorage.get('proposalId'),
        orderId: this.$SmartStorage.get('orderId'),
        itemId: null,
        authmobile: this.$SmartStorage.get('authMobile'),
        supplierId: this.$SmartStorage.get('supplierId'),
      }
      let res = await this.$service.SaveMiceAssistantInfo(params)
      if (res && res.success) {
        this.GetF1QuotedPriceTaskList()
        this.$router.push('/quoteSuccess')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.smallVenueTitle {
  position: relative;
  .venueTitle {
    background: #3875FE;
    color: #fff;
    font-size: .2rem;
    padding: .1rem 0 .4rem;
  }
}
.venueBox {
    padding-bottom: 1.2rem;
}
.footer_module {
  position: fixed;
  z-index: 99999;
  bottom: -1px;
  width: 100%;
  padding: 0.15rem;
  background: #fff;
  border-top: 1px solid #DEDEDE;
  .btn_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin-bottom: 0;
    }
    .infoBox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .amountBox {
        p,span {
          font-weight: 500;
          font-size: 10px;
          color: #909399;
        }
        .amount {
          font-weight: bold;
          font-size: 20px;
          color: #13161B;
        }
      }
    }
    .detailsBtn {
      color: #3875FE
    }
    .btn {
      background: #3875FE;
      border-radius: 4px;
      max-width: 2.2rem;
      padding: 0.1rem 0.2rem;
      min-width: 1.3rem;
      margin-left: 0.1rem;
      span {
        font-weight: 500;
        font-size: .17rem;
        color: #FFFFFF;
      }
    }
    .noSubmit {
      background: #ccc;
    }
  }
}
.amountDetailsBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
</style>
