<template>
  <div>
    <div v-for="(item, index) in venuesList" :key="index">
      <demand-card :venueItem="item" ref="demandCard" :formList='formList' :isGSK="isGSK"></demand-card>
    </div>
    <div class="saveQuote" @click="SaveQuotes">
      <a-spin v-show="loading"/>
      保存此品类报价
    </div>
  </div>
</template>
<script>
import demandCard from "./demandCard";
export default {
  name: 'demandQuote',
  components: {
    demandCard
  },
  props: {
    supplierInfo: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data () {
    return {
      venuesList: [],
      formList: [],
      isGSK: false,
      loading: false
    }
  },
  watch: {
    supplierInfo () {
      this.venuesList = []
      this.venuesList = this.supplierInfo.items
      this.getFormList()
    }
  },
  created() {
    this.venuesList = []
    this.venuesList = this.supplierInfo.items
    this.getFormList()
  },
  methods: {
    async getFormList () {
      let params ={
        collection: "cfg-supplier-HotelQuotationFormItems",
        filter: {
            tenantId: { "$in": ["*", this.$SmartStorage.get('tenantId')] },
            itemType: this.supplierInfo.itemType,
        },
      }
      let res = await this.$service.getsettings(params)
      if (res && res.content) {
        this.formList =  res.content.child || []
        this.isGSK = res.content.isGSK
      }
    },
    async SaveQuotes () {
      if (this.loading) {
        return
      }
      let paramsList = []
      const data = await Promise.all(this.$refs.demandCard.map((ref) => ref.editInfo()));
      let result = true
      await data.map(item => {
        if (item.result) {
          paramsList.push(item.params)
        } else {
          result = false
        }
      })
      if (!result) {
        return 
      }
      this.loading = true
      let res = await this.$service.BatchSaveQuotes(paramsList)
      this.loading = false
      if (res && res.success) {
        this.$toast("酒店报价保存成功！");
        this.$emit('updateAmount')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.saveQuote {
  background: #E1EAFF;
  border-radius: 4px;
  border: 1px solid #3875FE;
  font-size: .17rem;
  color: #3875FE;
  margin: 0 0.1rem;
  padding: 0.1rem;
  position: fixed;
  width: calc(100% - 0.2rem);
  bottom: 0.9rem;
}
</style>
