<template>
  <div class="venueInfo">
    <div class="venueName">
      <p>{{supplierInfo.supplierName}} <span v-if="supplierInfo.contactMobile" class="contactMobile">{{supplierInfo.contactMobile}}</span></p>
    </div>
    <div class="venueType">
      <span>
          {{supplierInfo.selectedTxt}}
      </span>
      <span>
          {{supplierInfo.offLineTxt}}
      </span>
      <span>
          {{supplierInfo.payModeTxt}}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    supplierInfo: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.venueInfo {
  box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.08);;
  border-radius: .03rem;
  margin: .1rem;
  background: #ffffff;
  position: relative;
  padding: .15rem;
  margin-top: -0.3rem;
  text-align: left;
  p {
    margin: auto;
  }
  .venueName {
    font-size: .17rem;
    font-weight: bold;
    color: #13161A;
    letter-spacing: -1px;
    margin-bottom: .1rem;
    .contactMobile {
      font-weight: 500;
      font-size: 12px;
      color: #909398;
    }
  }
  .venueType {
    span {
      color: var(--themeColor);
      font-size: .13rem;
      font-weight: 200;
      margin-right: 0.05rem;
      display: inline-block;
      padding: 0.02rem 0.05rem;
      position: relative;
      border-radius: 0.04rem;
      overflow: hidden;
      &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: .1;
        left: 0;
        bottom: 0;
        background-color: var(--themeColor);
      }
    }
  }
}
</style>
